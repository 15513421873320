<template>
  <b-card>
    <h4 class="mb-0">
      Edit Pegawai
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="NIP" label-for="nip">
              <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-input id="nip" v-model="dataStudent.nip" :state="errors.length > 0 ? false : null"
                  placeholder="NIP" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Lengkap" label-for="name">
              <validation-provider #default="{ errors }" name="Nama Lengkap" rules="required">
                <b-form-input id="name" v-model="dataStudent.name" :state="errors.length > 0 ? false : null"
                  placeholder="Nama Lengkap" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Panggilan" label-for="nickname">
              <validation-provider #default="{ errors }" name="Nama Panggilan" rules="required">
                <b-form-input id="nickname" v-model="dataStudent.nickname" :state="errors.length > 0 ? false : null"
                  placeholder="Nama Panggilan" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gelar Awal" label-for="first_title">
              <validation-provider #default="{ errors }" name="Gelar Awal" rules="required">
                <b-form-input id="first_title" v-model="dataStudent.first_title"
                  :state="errors.length > 0 ? false : null" placeholder="Gelar Awal" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gelar Akhir" label-for="last_title">
              <validation-provider #default="{ errors }" name="Gelar Akhir" rules="required">
                <b-form-input id="last_title" v-model="dataStudent.last_title" :state="errors.length > 0 ? false : null"
                  placeholder="Gelar Akhir" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jenis Kelamin" label-for="gender">
              <validation-provider #default="{ errors }" name="Jenis Kelamin" rules="required">
                <v-select id="gender" v-model="dataStudent.gender" :reduce="(gender) => gender.value"
                  :options="dataGender" :selectable="
                    (option) => !option.value.includes('select_value')
                  " label="text" placeholder="Pilih Jenis Kelamin" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tempat Lahir" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Tempat Lahir" rules="required">
                <b-form-input id="birth_place" v-model="dataStudent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Yogyakarta" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Lahir" label-for="birth_date">
              <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="required">
                <b-form-input id="birth_date" v-model="dataStudent.birth_date" :state="errors.length > 0 ? false : null"
                  placeholder="Tanggal Lahir" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Pendidikan Terakhir" label-for="education_level">
              <validation-provider #default="{ errors }" name="Pendidikan Terakhir" rules="required">
                <v-select id="education_level" v-model="dataStudent.education_level"
                  :reduce="(education_level) => education_level.value" :options="dataEducation" :selectable="
                    (option) => !option.value.includes('select_value')
                  " label="text" placeholder="Pilih Pendidikan Terakhir" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nama Institusi" label-for="educational_institution">
              <validation-provider #default="{ errors }" name="Nama Institusi" rules="required">
                <b-form-input id="educational_institution" v-model="dataStudent.educational_institution"
                  :state="errors.length > 0 ? false : null" placeholder="Nama Institusi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="KTP" label-for="ktp_number">
              <validation-provider #default="{ errors }" name="KTP" rules="required">
                <b-form-input id="ktp_number" v-model="dataStudent.ktp_number" :state="errors.length > 0 ? false : null"
                  placeholder="KTP" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Agama" label-for="religion">
              <validation-provider #default="{ errors }" name="Agama" rules="required">
                <v-select id="religion" v-model="dataStudent.religion" :reduce="(religion) => religion.value"
                  :options="dataReligion" label="text" placeholder="Pilih Agama" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kewarganegaraan" label-for="nationality">
              <validation-provider #default="{ errors }" name="Kewarganegaraan" rules="required">
                <v-select id="nationality" v-model="dataStudent.nationality" :reduce="(nationality) => nationality.name"
                  :options="dataCountries" label="name" placeholder="Pilih Kewarganegaraan" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Status Menikah" label-for="marital_status">
              <validation-provider #default="{ errors }" name="marital_status" rules="required">
                <v-select id="marital_status" v-model="dataStudent.marital_status"
                  :reduce="(marital_status) => marital_status.name" :options="dataMaritalStatus" label="name"
                  placeholder="Pilih Status Menikah" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Aktif" label-for="is_active">
              <validation-provider #default="{ errors }" name="is_active" rules="required">
                <v-select id="is_active" v-model="dataStudent.is_active" :reduce="(is_active) => is_active.name"
                  :options="dataIsActive" label="name" placeholder="Pilih Aktif / Tidak Aktif" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tipe Pegawai" label-for="type">
              <validation-provider #default="{ errors }" name="Tipe Pegawai" rules="required">
                <b-form-input id="type" v-model="dataStudent.type" :state="errors.length > 0 ? false : null"
                  placeholder="Tipe Pegawai" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="Provinsi" rules="required">
              <b-form-group label="Provinsi" label-for="province" :state="errors.length > 0 ? false : null">
                <v-select id="province" v-model="dataStudent.province" placeholder="Pilih Provinsi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataProvinces" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Kabupaten/Kota" rules="required">
              <b-form-group label="Kabupaten/Kota" label-for="city" :state="errors.length > 0 ? false : null">
                <v-select id="city" v-model="dataStudent.city" placeholder="Pilih Kabupaten/Kota"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterCities" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Kecamatan" rules="required">
              <b-form-group label="Kecamatan" label-for="district" :state="errors.length > 0 ? false : null">
                <v-select id="district" v-model="dataStudent.district" placeholder="Pilih Kecamatan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterDistrics" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="RW" label-for="rw">
              <validation-provider #default="{ errors }" name="RW" rules="required">
                <b-form-input id="rw" v-model="dataStudent.rw" :state="errors.length > 0 ? false : null"
                  placeholder="RW" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="RT" label-for="rt">
              <validation-provider #default="{ errors }" name="RT" rules="required">
                <b-form-input id="rt" v-model="dataStudent.rt" :state="errors.length > 0 ? false : null"
                  placeholder="RT" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Alamat" label-for="address">
              <validation-provider #default="{ errors }" name="Alamat" rules="required">
                <b-form-input id="address" v-model="dataStudent.address" :state="errors.length > 0 ? false : null"
                  placeholder="Alamat" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="No. Handphone 1" label-for="phone_1">
              <validation-provider #default="{ errors }" name="No. Handphone 1" rules="required">
                <b-form-input id="phone_1" v-model="dataStudent.phone_1" :state="errors.length > 0 ? false : null"
                  placeholder="No. Handphone 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="No. Handphone 2" label-for="phone_2">
              <validation-provider #default="{ errors }" name="No. Handphone 2" rules="required">
                <b-form-input id="phone_2" v-model="dataStudent.phone_2" :state="errors.length > 0 ? false : null"
                  placeholder="No. Handphone 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Whatsapp" label-for="whatsapp">
              <validation-provider #default="{ errors }" name="Whatsapp" rules="required">
                <b-form-input id="whatsapp" v-model="dataStudent.whatsapp" :state="errors.length > 0 ? false : null"
                  placeholder="Whatsapp" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      nip: '',
      name: '',
      nickname: '',
      first_title: '',
      last_title: '',
      gender: '',
      birth_place: '',
      birth_date: '',
      education_level: '',
      educational_institution: '',
      ktp_number: '',
      religion: '',
      ethnic: '',
      nationality: '',
      marital_status: '',
      is_active: '',
      type: '',
      province: '',
      city: '',
      district: '',
      subdistrict: '',
      rw: '',
      rt: '',
      address: '',
      phone_1: '',
      phone_2: '',
      whatsapp: '',
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
    },
    dataGender: [
      { value: 'M', text: 'Laki-laki' },
      { value: 'F', text: 'Perempuan' },
    ],
    dataReligion: [
      { value: 1, text: 'Islam' },
      { value: 2, text: 'Protestan' },
      { value: 3, text: 'Katolik' },
      { value: 4, text: 'Hindu' },
      { value: 5, text: 'Buddha' },
      { value: 6, text: 'Khonghucu' },
    ],
    dataEducation: [
      { value: '1', text: 'SD' },
      { value: '2', text: 'SMP' },
      { value: '3', text: 'SMA' },
      { value: '4', text: 'S1' },
      { value: '5', text: 'S2' },
      { value: '6', text: 'S3' },
    ],
    dataMaritalStatus: [
      { value: 1, text: 'Menikah' },
      { value: 2, text: 'Belum Menikah' },
    ],
    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/employees/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.nip = data.nip
      this.dataStudent.name = data.name
      this.dataStudent.nickname = data.nickname
      this.dataStudent.first_title = data.first_title
      this.dataStudent.last_title = data.last_title
      this.dataStudent.gender = data.gender
      this.dataStudent.birth_place = data.birth_place
      this.dataStudent.birth_date = data.birth_date.substr(0, 10)
      this.dataStudent.education_level = data.education_level
      this.dataStudent.educational_institution = data.educational_institution
      this.dataStudent.ktp_number = data.ktp_number
      this.dataStudent.religion = data.religion
      this.dataStudent.ethnic = data.ethnic
      this.dataStudent.nationality = data.nationality
      this.dataStudent.marital_status = data.marital_status
      this.dataStudent.is_active = data.is_active
      this.dataStudent.type = data.type
      this.dataStudent.province = data.province
      this.dataStudent.city = data.city
      this.dataStudent.district = data.district
      this.dataStudent.subdistrict = data.subdistrict
      this.dataStudent.rw = data.rw
      this.dataStudent.rt = data.rt
      this.dataStudent.address = data.address
      this.dataStudent.phone_1 = data.phone_1
      this.dataStudent.phone_2 = data.phone_2
      this.dataStudent.whatsapp = data.whatsapp
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            // Object.keys(this.locations).forEach(key => {
            //   dataFormStudent.append(key, this.locations[key])
            // })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/employees/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-pegawai' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
